body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loaderImage {
  animation: load8 3s infinite linear;
  margin-top: calc(50vh - 54px);
}

#loader{
  background: rgba(53, 116, 144, 0.9);
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  top: 0;
  display: none;
}

.loadderActive{
  display: block !important;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mobileMenu{
  position: absolute;
  top: 70px;
  transition: 1s;
  background: #74A0B3;
  left: 0;
  padding-left: 10px;
  width: 100vw;
  z-index: 1;
}

.mobileMenuHidden{
  left: -100vw !important;
  transition: 1s;
}