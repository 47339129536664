.BestsellerItem {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #ced4da;
  padding-right: 30px;
}
.BestsellerItem__delete {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  background: #c82333;
  height: 100%;
  border: none;
  padding: 0;
  color: white;

}




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.MenuEdit {
  display: flex;
  flex-wrap: wrap;
}
.MenuEdit__links {
  width: 33%;
}
.MenuEdit__menu {
  width: 67%;
  padding-left: 30px;
}
.MenuEdit__menu__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(116, 160, 179);

  padding: 5px;
}
.MenuEdit__menu__item__text {
  display: flex;
  align-items: center;
}
.MenuEdit__menu__item__text span {
  display: inline-block;
  margin-right: 20px;
}
.MenuEdit__menu__item__text button {
  border: none;
  background: none;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  /*line-height: 16px;*/
  justify-content: center;
}
.MenuEdit__menu__item__text button:focus, .MenuEdit__menu__item__text button:active {
  outline: none;
}
.MenuEdit__menu__item__text__link {
  color: gray;
}
.MenuEdit__menu__item__text__label {

}
.MenuEdit__menu__item__actions {

}
.MenuEdit__menu__item__actions button {
  background: red;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  width: 20px;
  height: 20px;
  /*align-items: center;*/
  line-height: 16px;
  justify-content: center;
  color: #fff;
}
.MenuEdit__categories {
  list-style-type: none;
  width: 100%;
}
.MenuEdit__categories input[type=checkbox]{
  margin-right: 5px;
}
.MenuEdit__categories[data-depth='0']{
  padding-left: 0;
  max-height: 500px;
  overflow: auto;
}
.MenuEdit__categories__item {
  position: relative;
  padding-right: 30px;
  border-top: 1px solid #f1f1f1;

}
.MenuEdit__categories__item label {
  cursor: pointer;
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 0;
}
.MenuEdit__categories__item button {
  position: absolute;
  right: 0;
  background: none;
  border: none;
  outline: none!important;
}
